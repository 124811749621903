<template>
    <b-container class="gallery">
        <b-row class="mx-0">
            <b-col cols="12" v-if=!loading>
                <b-container class="gallery-header">
                    <b-row>
                        <h1 v-if="collection.title"
                            class="mb-5">
                        {{collection.title}}</h1>
                        <h1 v-else-if="loading"
                        class="mb-5 mt-5">
                        Galerij
                        </h1>
                    </b-row>
                    <b-row>
                        <h3>{{collection.description}}</h3>
                    </b-row>
                </b-container>
                <b-card-group v-if="cards.length > 0" fluid class="mx-0">
                    <b-card :img-src="c.thumbnail" v-for="c in cards" :key="c.id"
                        :style="cardwidth"
                        img-height="250px"
                        :title="c.title"
                        @click="view(card)">
                        <b-card-text>{{c.description}}</b-card-text>
                    </b-card>
                </b-card-group>
            </b-col>
            <div class="spinner" v-else>
            </div>
        </b-row>
    </b-container>
</template>

<script>
/*eslint-disable*/
//TODO: Rewrite to be a collection
export default {
    data () {
        return {
            title : "",
            cards : [],
            description: "",
            loading: false,

            collection: null,
        }
    },
    async created() {
        this.loading = true;
        await this.getCollection();
        await this.getCards();
        this.loading = false;
    },
    methods : {
        async getCollection() {
            this.collection = await this.$store.dispatch("getCollectionByTitle", this.collectionTitle)
        },
        async getCards() {
            this.cards = await this.$store.dispatch("getCardsByCollectionId", this.collection.id);
        },

        //deprecated:
        async loadGallery() {
            if (typeof this.$route.query.Title  == "undefined")
                 await this.getGallery("Galerij");
            else
                await this.getGallery(this.$route.query.Title);
        },
        async getGallery(galleryTitle) {
            this.loading = true;
            this.$store.dispatch("getGalleryByName", galleryTitle)
            .then(response => response.json())
            .then(result => {
                this.title = result.galleryTitle;
                this.description = result.galleryDescription;
                result.cards.forEach(element => {
                    this.cards.push({card: element});
                });
            })
            this.loading=false;
        },
        view(card) {
            //TODO
            this.$router.push({
                path : card.type,
                query : {
                    Title : card.title,
                    Id : card.contentId
                }
            }).catch(() => {});
        }
    },
    computed : {
        collectionTitle() {
            var title = typeof this.$route.query.Title == "undefined" 
                ? "Galerij" 
                : this.$route.query.Title;
            return title;
        },
        mobile() {
            return this.$vuetify.breakpoint.sm 
            || this.$vuetify.breakpoint.xs 
            || this.$vuetify.breakpoint.s;
        },
        cardwidth() {
            var result = this.mobile ? "max-wdith: 100%;" : "max-width: 33%;";
            return result;
        }
    }

}
</script>

<style>

    .card-body {
        padding : 10px;
    }
    .card:hover {
        outline: 5px solid #FF005D !important;
    }
    .card:active {
        outline-color: #fd74a6;
    }
</style>